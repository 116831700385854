import {ApolloClient, ApolloLink, InMemoryCache, ServerError, split} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {removeTypenameFromVariables} from '@apollo/client/link/remove-typename';
import {getMainDefinition} from '@apollo/client/utilities';
import {GraphQLErrorResolver, NotificationType, NotificationsActionCreator} from '@eon.cz/apollo13-frontend-common';
import {Action, Dispatch} from '@reduxjs/toolkit';
import {addBreadcrumb} from '@sentry/browser';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import {isEqual} from 'date-fns';
import merge from 'deepmerge';
import {GraphQLError, GraphQLFormattedError} from 'graphql';
import cloneDeepWith from 'lodash/cloneDeepWith';
import {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {v4 as uuidv4} from 'uuid';
import fragment from '../../node_modules/@eon.cz/apollo13-graphql/lib/fragmentTypesV3.json';
import {Lang} from '../Lang';
import {AuthActionCreator} from '../modules/Auth/actions/AuthAction';

const statusCode = [403, 503];

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const removeTypenameLink = removeTypenameFromVariables();

const errorLink = (dispatch: Dispatch) =>
    onError(({graphQLErrors, networkError, response, operation}) => {
        if (networkError?.name !== 'TypeError') {
            // Add error to sentry breadcrumbs
            addBreadcrumb({
                category: 'gql.error',
                data: {
                    graphQLErrors: cloneDeepWith(graphQLErrors),
                    networkError: cloneDeepWith(networkError),
                    response: cloneDeepWith(response),
                    operation: cloneDeepWith(operation),
                },
            });

            if (typeof graphQLErrors === 'object' && graphQLErrors.length > 0) {
                const invalidLogin = (graphQLErrors as ReadonlyArray<GraphQLFormattedError & {code?: string}>).reduce(
                    (res, error) => (error.code === 'INVALID_LOGIN' ? error : res),
                    {} as GraphQLError,
                );
                const isInvalidLogin = invalidLogin?.code === 'INVALID_LOGIN';
                if (isInvalidLogin) {
                    AuthActionCreator(dispatch).logout(apolloClient, invalidLogin.code);
                    return;
                }
                GraphQLErrorResolver.resolveErrors(dispatch, graphQLErrors);
                // If there are GraphQL errors, ignore network
            } else {
                if (networkError) {
                    const result = (networkError as ServerError).result as {error: {code: string}; code?: string; errors?: Array<{extensions: {code: string}}>};
                    const code = result?.error?.code ?? result?.errors?.[0]?.extensions?.code;
                    const addNotification = NotificationsActionCreator(dispatch).addNotification;
                    if (code === 'DEACTIVATED_ACCOUNT') {
                        addNotification({
                            type: NotificationType.ERROR,
                            text: <FormattedMessage id={Lang.UCET_DEAKTIVOVAN} />,
                        });
                        AuthActionCreator(dispatch).logout(apolloClient);
                        return;
                    } else if (code === 'INVALID_TOKEN' || code === 'TOKEN_INACTIVITY_EXCEEDED' || code === 'REVOKED_TOKEN' || code === 'INVALID_LOGIN') {
                        AuthActionCreator(dispatch).logout(apolloClient, code);
                        return;
                    } else if (networkError && 'statusCode' in networkError && statusCode.includes(networkError.statusCode)) {
                        const error = networkError.statusCode === 503 ? 'loginDisabled' : undefined;
                        AuthActionCreator(dispatch).logout(apolloClient, error);
                        return;
                    } else {
                        // Other error
                        addNotification({
                            type: NotificationType.ERROR,
                            text: <FormattedMessage id={Lang.CHYBA_SITE} />,
                        });
                    }
                }
            }
        }
    });

const PASSWORD_FIELDS = ['heslo', 'hesloznovu', 'password', 'passwordagain', 'pass'];

/**
 * Remove sensitive fields from the object (deep)
 */
export const cloneWithHiddenPasswords = (obj: Record<string, any>) =>
    cloneDeepWith(obj, (__, key) => {
        if (PASSWORD_FIELDS.indexOf(String(key)?.toLowerCase()) >= 0) {
            return '*****';
        }
        return undefined;
    });

const createClient = (initialState: null, dispatch: Dispatch) => {
    /**
     * Middleware that adds info about GQL call to Sentry breadcrumbs
     */
    const sentryMiddleware = new ApolloLink((operation, forward) => {
        // Generate request id
        const requestId = uuidv4();

        // Add request to sentry breadcrumbs
        addBreadcrumb({
            category: 'gql.request',
            data: {
                requestId,
                operationName: operation.operationName,
                variables: cloneWithHiddenPasswords(operation.variables),
            },
        });

        // Set X-Request-Id header in context
        const oldContext = operation.getContext();
        const newContext = {
            ...oldContext,
            headers: !!oldContext.headers ? {...oldContext.headers, ['X-Request-Id']: requestId} : {['X-Request-Id']: requestId},
        };
        operation.setContext(newContext);

        // Continue request
        return forward(operation);
    });

    const httpLink: any = createUploadLink({
        uri: '/api/graphql',
        fetchOptions: {credentials: 'same-origin'},
        headers: {
            'X-Apollo-Operation-Name': 'graphql',
        },
    });

    const link =
        typeof window !== 'undefined'
            ? split(({query}) => {
                  const definition = getMainDefinition(query);
                  return definition.kind === 'OperationDefinition' && (definition.operation === 'query' || definition.operation === 'mutation');
              }, httpLink)
            : httpLink;

    return new ApolloClient({
        connectToDevTools: typeof window !== 'undefined',
        ssrMode: typeof window !== 'undefined',
        assumeImmutableResults: false,
        link: ApolloLink.from([sentryMiddleware, errorLink(dispatch), removeTypenameLink, link]),
        cache: new InMemoryCache({
            possibleTypes: fragment.possibleTypes,
            typePolicies: {
                Query: {
                    fields: {
                        adresniMista: {
                            merge: true,
                        },
                        elektrina: {
                            merge: true,
                        },
                        nastaveni: {
                            merge: true,
                        },
                        plyn: {
                            merge: true,
                        },
                        ucty: {
                            merge: true,
                        },
                        servisniZakazky: {
                            merge: true,
                        },
                        energetickeSpolecenstvi: {
                            merge: true,
                        },
                        sopWebZD24: {
                            merge: true,
                        },
                        prilohyKeStazeni: {
                            merge: true,
                        },
                        smlouvyOdbernychMist: {
                            merge: true,
                        },
                    },
                },
                SmlouvaOdbernehoMista: {
                    fields: {
                        elektrina: {
                            merge: true,
                        },
                    },
                },
            },
        }).restore(initialState || {}),
        defaultOptions: {
            query: {
                fetchPolicy: 'network-only',
            },
            watchQuery: {
                nextFetchPolicy(lastFetchPolicy) {
                    if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'network-only') {
                        return 'cache-first';
                    }
                    return lastFetchPolicy;
                },
            },
        },
    });
};

export let apolloClient: ApolloClient<any>;

export const initApollo = (initialState: null, dispatch: Dispatch<Action>) => {
    const _apolloClient = apolloClient ?? createClient(initialState, dispatch);

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [...sourceArray, ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))],
        });

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
};

export function useApollo(pageProps: any, dispatch: Dispatch) {
    const state = pageProps[APOLLO_STATE_PROP_NAME];
    return useMemo(() => initApollo(state, dispatch), [dispatch, state]);
}
