import {scan} from 'react-scan';

import {ApolloProvider} from '@apollo/client';
import {CacheProvider} from '@emotion/react';
import {Komodita} from '@eon.cz/apollo13-graphql';
import {CssBaseline} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';
import {Breadcrumb, captureException, init, withScope} from '@sentry/browser';
import {cs} from 'date-fns/locale/cs';
import {NextApiRequest} from 'next';
import App, {AppProps} from 'next/app';
import Head from 'next/head';
import Router, {useRouter} from 'next/router';
import {done, start} from 'nprogress';
import {ErrorInfo, useEffect, useMemo} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {createIntl, createIntlCache, CustomFormats, FormattedMessage, RawIntlProvider} from 'react-intl';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import persistStore from 'redux-persist/lib/persistStore';
import * as gtag from '../client/gtag';
import {ErrorFallbackLogin} from '../client/modules/Auth/components/ErrorFallbackLogin';
import {getLoggerUserForDebug} from '../client/modules/Auth/service/AuthService';
import {cache, useTheme} from '../client/modules/Common/components/layout/theme';
import {KomoditaContext} from '../client/modules/Common/contexts/KomoditaContext';
import {browserSupoort, isJsonString} from '../client/modules/Common/services/CommonService';
import {isNotNullOrUndefinedOrEmpty} from '../client/modules/Common/services/FileService';
import {SapErrorResolver} from '../client/modules/Utils/SapErrorResolver';
import store from '../client/with/createStore';
import {useApollo} from '../client/with/withApolloProvider';
import {WithProfile} from '../client/with/withProfile';
import Czech from '../lang/cs.json';

/* Root stylesheet */
import {LocalizationProvider} from '@mui/x-date-pickers';
import {CommonActionCreator} from '../client/modules/Common/actions/CommonActions';
import {DynamicFavicon} from '../client/modules/Common/components/layout/DynamicFavicon';
import {getFromLS, saveToLS} from '../client/modules/Common/services/PageService';
import {environment} from '../client/modules/Utils/environment';
import '../public/static/fonts/BrixSans/stylesheet.css';
import '../public/static/nprogress/nprogress.css';
import '../public/static/styles.css';

// This is optional but highly recommended
// since it prevents memory leak
const intlCache = createIntlCache();

Router.events.on('routeChangeStart', () => start());
Router.events.on('routeChangeComplete', () => done());
Router.events.on('routeChangeError', () => done());

init({
    dsn: isNotNullOrUndefinedOrEmpty(environment.SENTRY_DSN) ? environment.SENTRY_DSN : undefined,
    environment: environment.KUBERNETES_NAMESPACE,
    ignoreErrors: ['ApolloError: Load failed', 'ApolloError: Network error: Failed to fetch', 'TypeError: Failed to fetch'],
    beforeSend: (event) => {
        if (!!event.exception && !!event.exception.values && event.exception.values.length > 0) {
            for (const ex of event.exception.values) {
                if (ex.type === 'Error' && (ex?.value?.startsWith('GraphQL error') || ex.value?.startsWith('Network error'))) {
                    // Filter out all GraphQL errors for now
                    return null;
                }
            }
        }

        // Enrich user by stored email
        const email = getLoggerUserForDebug();
        if (typeof email === 'string') {
            const hiddenUser = email.split('@')[1];
            event.user = !!event.user ? {...event.user, email: hiddenUser} : {email: hiddenUser};
        }
        return event;
    },
    beforeBreadcrumb: (breadcrumb: Breadcrumb): Breadcrumb | null => {
        if ((breadcrumb.category === 'fetch' || breadcrumb.category === 'xhr') && !!breadcrumb.data && breadcrumb.data.url === '/api/graphql') {
            // We log graphql requests ourselves so ignore them
            return null;
        }

        // Pass on
        return breadcrumb;
    },
});
if (typeof window !== 'undefined') {
    const REACT_SCAN_ENABLED = process.env.NEXT_PUBLIC_REACT_SCAN_ENABLED === 'true';
    scan({
        enabled: REACT_SCAN_ENABLED,
        log: true, // logs render info to console (default: false)
        playSound: false,
    });
}

type Props = AppProps & {
    statusCode: number;
    locale: string;
    formats: CustomFormats | undefined;
    komodita: Komodita;
};

const persistor = persistStore(store);

const MyApp = (props: Props) => {
    const {Component, pageProps, komodita, locale, formats} = props;

    const {events, push} = useRouter();

    const {theme} = useTheme();

    const apolloClient = useApollo(pageProps, store.dispatch);

    const {setMobileProps} = CommonActionCreator(store.dispatch);

    const [shortLocale] = locale ? locale.split('-') : ['en'];

    const messages = useMemo(() => {
        switch (shortLocale) {
            case 'cs':
                return Czech;
                break;
            default:
                return Czech;
        }
    }, [shortLocale]);

    const myErrorHandler = (error: Error, errorInfo: ErrorInfo) => {
        withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key as keyof ErrorInfo]);
            });

            captureException(error);
        });
    };

    useEffect(() => {
        return [window, document].forEach((el) =>
            el.addEventListener('message', (nativeEvent: any) => {
                const isMobile = isJsonString(nativeEvent?.data);

                if (isMobile) {
                    const existOldData = isJsonString(window.localStorage.getItem('mobile') ?? '');
                    if (existOldData) {
                        const data = getFromLS('mobile');
                        const parseNativeData = JSON.parse(nativeEvent?.data);
                        saveToLS('mobile', {...{...parseNativeData, openURL: undefined}});
                        setMobileProps({...data, ...JSON.parse(nativeEvent?.data)});
                        return;
                    }
                    saveToLS('mobile', JSON.parse(nativeEvent?.data));
                    setMobileProps({...JSON.parse(nativeEvent?.data)});
                }
            }),
        );
    }, [setMobileProps]);

    useEffect(() => {
        if (!browserSupoort(window.navigator.userAgent)) {
            push('/warning.html');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleRouteChange = (url: URL) => {
            gtag.pageview(url);
        };
        events.on('routeChangeComplete', handleRouteChange);
        return () => {
            events.off('routeChangeComplete', handleRouteChange);
        };
    }, [events]);

    useEffect(() => {
        // Remove the server-side injected CSS.
        SapErrorResolver.init();
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }, []);

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ApolloProvider client={apolloClient}>
                    <RawIntlProvider value={createIntl({locale, messages, formats}, intlCache)}>
                        <CacheProvider value={cache}>
                            <ThemeProvider theme={theme}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={cs}>
                                    <CssBaseline />
                                    <KomoditaContext.Provider
                                        value={{
                                            komodita,
                                        }}
                                    >
                                        <Head>
                                            <meta
                                                name="viewport"
                                                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                                            />
                                            <title>
                                                <FormattedMessage id="portal" />
                                            </title>
                                        </Head>
                                        <DynamicFavicon komodita={komodita} />
                                        <ErrorBoundary FallbackComponent={ErrorFallbackLogin} onError={myErrorHandler}>
                                            <WithProfile>
                                                <Component {...pageProps} />
                                            </WithProfile>
                                        </ErrorBoundary>
                                    </KomoditaContext.Provider>
                                </LocalizationProvider>
                            </ThemeProvider>
                        </CacheProvider>
                    </RawIntlProvider>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    );
};

const getInitialProps: typeof App.getInitialProps = async (appContext) => {
    const {ctx} = appContext;
    let komoditaReq: string | null = null;
    let komoditaCookie: string | null = null;

    if (ctx?.req) {
        // We are in Next - get from request
        if (ctx.req.url?.startsWith('/elektrina')) {
            komoditaReq = 'elektrina';
        } else if (ctx.req.url?.startsWith('/plyn')) {
            komoditaReq = 'plyn';
        }
        komoditaCookie = (await import('../client/modules/Profile')).ProfileService.getKomoditaFromReq(ctx.req as NextApiRequest);
    } else {
        // We are in browser - get from browser
        if (ctx.pathname.startsWith('/elektrina')) {
            komoditaReq = 'elektrina';
        } else if (ctx.pathname.startsWith('/plyn')) {
            komoditaReq = 'plyn';
        }
        komoditaCookie = (await import('../client/modules/Profile')).ProfileService.getKomodita();
    }

    let komodita = null;
    if (komoditaReq === 'elektrina') {
        komodita = Komodita.ELEKTRINA;
    } else if (komoditaReq === 'plyn') {
        komodita = Komodita.PLYN;
    } else if (komoditaCookie === 'ELEKTRINA') {
        komodita = Komodita.ELEKTRINA;
    } else if (komoditaCookie === 'PLYN') {
        komodita = Komodita.PLYN;
    }

    const [appProps] = await Promise.all([App.getInitialProps(appContext)]);

    const formats = getFormats();

    return {
        ...appProps,
        locale: getLocales(ctx?.req?.headers['accept-language']),
        formats,
        komodita,
    };
};

const getLocales = (str: string | undefined) => {
    return str?.split(',').map((type) => type.split(';')[0]?.trim().replace('*', ''))[0] ?? 'cs';
};

const getFormats = () => ({
    number: {
        CS: {
            style: 'currency',
            currency: 'CZK',
        },
        EN: {
            style: 'currency',
            currency: 'US',
        },
    },
});

MyApp.getInitialProps = getInitialProps;

export default MyApp;
