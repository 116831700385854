import {SmlouvaOdbernehoMistaStav} from '@eon.cz/apollo13-graphql';
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {OdbernaMistaStore} from '../../../Store/OdbernaMistaStore';
import {OdbernaMistaFormFilterModel} from '../model/OdbernaMistaFormFilterModel';
import {OdbernaMistaSmartMereniFilterModel} from '../model/OdbernaMistaSmartMereniFilterModel';
import {OdberneMistoDetailTabValueModel, OdberneMistoDetailTabValueModelOstatni} from '../model/OdberneMistoDetailTabValueModel';

export const initialState = {
    filter: {
        datumDo: null,
        datumOd: null,
        stav: SmlouvaOdbernehoMistaStav.AKTIVNI,
        typMereni: 'vse',
        eanFulltext: null,
        eicEanSpotrebni: null,
        eicFulltext: null,
        nazev: null,
    },
    smartMereni: {
        filter: {},
    },
    tabDetailValue: OdberneMistoDetailTabValueModel.HLAVNI,
    tabDetailValueOstatni: OdberneMistoDetailTabValueModelOstatni.HLAVNI,
    tabValue: 0,
} as OdbernaMistaStore;

export const odbernaMistaSlice = createSlice({
    name: 'odbernaMista',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setOmFilter: (state: OdbernaMistaStore, {payload}: PayloadAction<OdbernaMistaFormFilterModel>): OdbernaMistaStore => ({
            ...state,
            filter: {...state.filter, ...payload},
        }),

        setResetOmFilter: (state: OdbernaMistaStore): OdbernaMistaStore => ({
            ...state,
            filter: initialState.filter,
        }),

        setSmartMereniFilterReducer: (state: OdbernaMistaStore, {payload}: PayloadAction<OdbernaMistaSmartMereniFilterModel>): OdbernaMistaStore => ({
            ...state,
            smartMereni: {
                ...state.smartMereni,
                filter: {...state.smartMereni.filter, ...payload},
            },
        }),

        setResetSmartMereniFilter: (state: OdbernaMistaStore): OdbernaMistaStore => ({
            ...state,
            smartMereni: {
                ...state.smartMereni,
                filter: initialState.smartMereni.filter,
            },
        }),
        setTabDetailValueSlice: (state: OdbernaMistaStore, {payload}: PayloadAction<OdberneMistoDetailTabValueModel>): OdbernaMistaStore => ({
            ...state,
            tabDetailValue: payload,
        }),

        setTabDetailValueOstatniSlice: (state: OdbernaMistaStore, {payload}: PayloadAction<OdberneMistoDetailTabValueModelOstatni>): OdbernaMistaStore => ({
            ...state,
            tabDetailValueOstatni: payload,
        }),

        setTabValueSlice: (state: OdbernaMistaStore, {payload}: PayloadAction<number>): OdbernaMistaStore => ({
            ...state,
            tabValue: payload,
        }),
    },
});

export const {
    setOmFilter,
    setResetOmFilter,
    setResetSmartMereniFilter,
    setSmartMereniFilterReducer,
    setTabDetailValueSlice,
    setTabDetailValueOstatniSlice,
    setTabValueSlice,
} = odbernaMistaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const odbernaMista = (state: Store) => state.odbernaMista;
export const selectOdbernaMista = createSelector([odbernaMista], (odbernaMista) => odbernaMista);
