import {Div, EgdLogo, GasLogo, useMatches} from '@eon.cz/apollo13-frontend-common';
import {styled} from '@mui/material/styles';
import {ReactNode} from 'react';
import {CookiesButton} from '../../../Auth/components/CookiesButton';
import {getFromLS} from '../../services/PageService';
import {theme} from './theme';

export const getDeviceType = () => {
    const ua = typeof navigator !== 'undefined' ? navigator.userAgent : '';
    const uaNew = typeof navigator !== 'undefined' ? navigator.userAgentData : undefined;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
    }
    if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua) || uaNew?.mobile) {
        return 'mobile';
    }
    return 'desktop';
};

const DivWrapper = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.secondary.main,
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'auto',
}));
const DivChildren = styled('div')(() => ({
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    overflowY: 'auto',
}));

type Props = {
    children: ReactNode;
    pageOff?: boolean;
    login?: boolean;
    logoOff?: boolean;
};

/**
 * Wrapper komponenta obsahující pozadí aplikace
 */
export const LandingBackground = ({children, pageOff, login, logoOff}: Props) => {
    const isMobile = getFromLS('mobile').isMobile;
    const matches = useMatches();
    return (
        <DivWrapper>
            <DivChildren role="main">
                {login && !logoOff && (
                    <Div sx={{display: 'flex', justifyContent: !isMobile ? 'space-between' : 'center', ml: 2, mr: 2}}>
                        <EgdLogo width={isMobile || matches ? 90 : 120} height={isMobile || matches ? 50 : 100} pathFill={theme.palette.error.main} />

                        {!isMobile && <GasLogo width={matches ? 90 : 120} height={matches ? 50 : 100} pathFill="white" />}
                    </Div>
                )}
                {children}
            </DivChildren>
            {!pageOff && !isMobile && !matches && (
                <Div
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                    }}
                >
                    <CookiesButton isMobile={false} />
                </Div>
            )}
            {!login && !logoOff && (
                <Div sx={{display: 'flex', justifyContent: !isMobile ? 'space-between' : 'right', width: '90%', maxWidth: '1200px'}}>
                    <EgdLogo width={matches ? 70 : 100} height={matches ? 70 : 100} pathFill={theme.palette.error.main} />
                    {!isMobile && <GasLogo width={matches ? 70 : 100} height={matches ? 70 : 100} pathFill="white" />}
                </Div>
            )}
        </DivWrapper>
    );
};
