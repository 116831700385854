import {
    NapetovaUroven,
    Priloha,
    PrilohaUploadInput,
    RevizniZpravaElektrinaDistribucniSazba,
    RevizniZpravaElektrinaOmBezpecnyProvoz,
    RevizniZpravaElektrinaOmNn,
    RevizniZpravaElektrinaOmNnPocetFazi,
    RevizniZpravaElektrinaOmNnSpotrebic,
    RevizniZpravaElektrinaOmNnVypinaciCharakteristika,
    RevizniZpravaElektrinaOmPrevodProud,
    RevizniZpravaElektrinaOmProud,
    RevizniZpravaElektrinaOmProudInput,
    RevizniZpravaElektrinaOmTridaPresnosti,
    RevizniZpravaElektrinaOmVn,
    RevizniZpravaElektrinaOmVnNapeti,
    RevizniZpravaElektrinaOmVnPrevodNapeti,
    RevizniZpravaElektrinaPotvrzeniTyp,
    RevizniZpravaStav,
    RevizniZpravyFilter,
    ZadostStav,
} from '@eon.cz/apollo13-graphql';
import isEqual from 'lodash/isEqual';
import {Lang} from '../../../Lang';
import {parseDecimal, toApiDateTimeFrom, toApiDateTimeTo} from '../../Utils';
import {ZadostiMenuItemModel} from '../../Zadosti/model';
import {RevizniZpravyFilterModel, RevizniZpravyMenuItemModel} from '../model';

export const RevizniZpravyMenuMap = {
    0: 'KONCEPT',
    1: 'vse',
    2: 'VYRESENO',
    3: 'V_RESENI',
    4: 'ZAMITNUTO',
} as Record<number, RevizniZpravyMenuItemModel>;

export const NapetovaUrovenRevizniZpravy = {
    VVN: 'VVN',
    VN: 'VN',
    NN: 'NN',
} as const;

export type NapetovaUrovenRevizniZpravy = (typeof NapetovaUrovenRevizniZpravy)[keyof typeof NapetovaUrovenRevizniZpravy];

export const REVIZNI_ZPRAVY_MAX_PRILOH = 10;

const PREFIX_FORM_NAME = 'REVIZNI_ZPRAVY';

export const REVIZNI_ZPRAVY_VSE = 'vse';
export const REVIZNI_ZPRAVY_STAV_RESENI_VSE = 'vse';
export const REVIZNI_ZPRAVY_NAPETOVA_UROVEN_VSE = 'vse';

export const FILTER_FORM_NAME = `${PREFIX_FORM_NAME}_FILTER_FORM`;
export const CREATE_FORM_NAME = `${PREFIX_FORM_NAME}_CREATE_FORM`;

// získání hodnot pro filtraci napěťové úŕovně
export const getNapetovaUroven = (): Array<NapetovaUrovenRevizniZpravy | 'vse'> => {
    return [REVIZNI_ZPRAVY_NAPETOVA_UROVEN_VSE, ...Object.values(NapetovaUrovenRevizniZpravy)];
};

// získání hodnot pro filtraci napěťové uŕovně pro formulář
export const getNapetovaUrovenForm = (): Array<NapetovaUrovenRevizniZpravy | 'vse'> => {
    return [...Object.values(NapetovaUrovenRevizniZpravy)];
};

// získání hodnot pro filtraci stavu
export const getStavyRevizniZpravy = (): Array<RevizniZpravaStav | 'vse'> => {
    return [REVIZNI_ZPRAVY_VSE, ...Object.values(RevizniZpravaStav)];
};

// získání hodnot bezpečného provozu pro formulář
export const getSchopnostBezpecnehoProvozu = (): RevizniZpravaElektrinaOmBezpecnyProvoz[] => {
    return [...Object.values(RevizniZpravaElektrinaOmBezpecnyProvoz)];
};

// získání hodnot počtu fází pro formulář
export const getPocetFazi = (): RevizniZpravaElektrinaOmNnPocetFazi[] => {
    return [...Object.values(RevizniZpravaElektrinaOmNnPocetFazi)];
};

// získání hodnot vypínací charakteristiky pro formulář
export const getVypinaciCharakteristika = (): RevizniZpravaElektrinaOmNnVypinaciCharakteristika[] => {
    return [...Object.values(RevizniZpravaElektrinaOmNnVypinaciCharakteristika)];
};

// získání hodnot pro převod proudu pro formulář
export const getPrevodProud = (): RevizniZpravaElektrinaOmPrevodProud[] => {
    return [...Object.values(RevizniZpravaElektrinaOmPrevodProud)];
};

// získání hodnot pro převod napětí pro formulář
export const getPrevodNapeti = (): RevizniZpravaElektrinaOmVnPrevodNapeti[] => {
    return [...Object.values(RevizniZpravaElektrinaOmVnPrevodNapeti)];
};

// získání hodnot pro třídy přesnosti pro formulář
export const getTridaPresnosti = (): RevizniZpravaElektrinaOmTridaPresnosti[] => {
    return [...Object.values(RevizniZpravaElektrinaOmTridaPresnosti)];
};

// získání hodnot pro typ potvrzení revizní zprávy pro formulář
export const getPotvrzeniTyp = (): RevizniZpravaElektrinaPotvrzeniTyp[] => {
    return [...Object.values(RevizniZpravaElektrinaPotvrzeniTyp)];
};

// nastavení barvy podle stavu v levém menu
export const getColorByLeftMenuItem = (stavType: RevizniZpravyMenuItemModel | undefined) => {
    switch (stavType) {
        case 'vse':
            return 'grey';
        case RevizniZpravaStav.V_RESENI:
            return 'orange';
        case RevizniZpravaStav.KONCEPT:
            return 'secondary';
        case RevizniZpravaStav.VYRESENO:
            return 'green';
        case RevizniZpravaStav.ZAMITNUTO:
            return 'error';
        case undefined:
            return 'grey';
        default:
            return 'grey';
    }
};

// nastavení textace podle stavu v levém menu
export const getNameByLeftMenuType = (stavType: RevizniZpravyMenuItemModel): string => {
    switch (stavType) {
        case REVIZNI_ZPRAVY_VSE:
            return Lang.REVIZNI_ZPRAVY_MENU_VSE;
        case RevizniZpravaStav.V_RESENI:
            return Lang.REVIZNI_ZPRAVY_MENU_V_RESENI;
        case RevizniZpravaStav.KONCEPT:
            return Lang.REVIZNI_ZPRAVY_MENU_KONCEPT;
        case RevizniZpravaStav.VYRESENO:
            return Lang.REVIZNI_ZPRAVY_MENU_VYRESENO;
        case RevizniZpravaStav.ZAMITNUTO:
            return Lang.REVIZNI_ZPRAVY_MENU_ZAMITNUTO;
        default:
            return Lang.REVIZNI_ZPRAVY_MENU_NEZNAME;
    }
};

// nastavení ikony podle stavu v levém menu
export const getIconByLeftMenuType = (stavType: RevizniZpravyMenuItemModel) => {
    switch (stavType) {
        case 'vse':
            return 'all_inbox';
        case RevizniZpravaStav.V_RESENI:
            return 'donut_large';
        case RevizniZpravaStav.KONCEPT:
            return 'drafts';
        case RevizniZpravaStav.VYRESENO:
            return 'check';
        case RevizniZpravaStav.ZAMITNUTO:
            return 'error_outline';
        default:
            return undefined;
    }
};

// získání pole stavu revizních zpráv
export const getAllRevizniZpravyStavy = (): RevizniZpravaStav[] => {
    return Object.values(RevizniZpravaStav);
};

// získání pole všech stavů pro revizní zprávy pro levé menu
export const getAllLeftRevizniZpravyMenuItems = (): RevizniZpravyMenuItemModel[] => {
    const [koncept, vReseni, vyreseno, zamitnuto, ...revizniZpravyStav] = getAllRevizniZpravyStavy();
    return [koncept, 'vse', ...revizniZpravyStav, vReseni, vyreseno, zamitnuto];
};

export const isDividerInLeftMenu = (type: ZadostiMenuItemModel): boolean => {
    switch (type) {
        case 'vse':
        case ZadostStav.KONCEPT:
            return true;
        case ZadostStav.CEKA_NA_OPRAVU:
            return false;
        case ZadostStav.CEKA_NA_PODPIS:
            return false;
        case ZadostStav.VYRESENO:
            return false;
        case ZadostStav.V_RESENI:
            return false;
        case ZadostStav.ZAMITNUTO:
            return false;
        default:
            return false;
    }
};

// kontrola,zda můžeme vyresetovat hodnoty ve filtraci
export const canResetRevizniZpravyFilter = (initialFilter: RevizniZpravyFilterModel, currentFilter: RevizniZpravyFilterModel): boolean => {
    return !isEqual(initialFilter, currentFilter);
};

// mapování hodnot z filtrace na GraphQL volání
export const mapToRevizniZpravyFiltratioGraphqlModel = (selectedMenuItem: RevizniZpravaStav | 'vse', filter: RevizniZpravyFilterModel): RevizniZpravyFilter => {
    return {
        ...filter,
        stav: selectedMenuItem !== REVIZNI_ZPRAVY_VSE ? selectedMenuItem : filter.stav !== REVIZNI_ZPRAVY_VSE ? filter.stav : null,
        stavReseni: filter.stavReseni !== REVIZNI_ZPRAVY_STAV_RESENI_VSE ? filter.stavReseni : null,
        cisloFulltext: filter.cisloFulltext ? filter.cisloFulltext : null,
        datumVytvoreniOd: filter.datumVytvoreniOd ? toApiDateTimeFrom(filter.datumVytvoreniOd) : null,
        datumVytvoreniDo: filter.datumVytvoreniDo ? toApiDateTimeTo(filter.datumVytvoreniDo) : null,
        datumOdeslaniOd: filter.datumOdeslaniOd ? toApiDateTimeFrom(filter.datumOdeslaniOd) : null,
        datumOdeslaniDo: filter.datumOdeslaniDo ? toApiDateTimeTo(filter.datumOdeslaniDo) : null,
        napetovaUroven: filter.napetovaUroven !== REVIZNI_ZPRAVY_NAPETOVA_UROVEN_VSE ? filter.napetovaUroven : null,
        vytvoril: filter.vytvoril ? filter.vytvoril.id : null,
    };
};

// nastavení barvy pro jednotlivé stavy řešení
export const getStavyReseniColorTypeUp = () => {
    return {
        CEKA_NA_ODESLANI: 'neutral',
        ODESLANO: 'inprogress',
        RZ_ZAMITNUTA: 'negative',
        PODMINKY_SPLNENY: 'inprogress',
        PODMINKY_NESPLNENY: 'negative',
        OPM_PRIPRAVENO: 'inprogress',
    };
};

// transformace dat objektu RevizniZpravaCreateInput pro validaci a odeslání na BE
export const getTransformRevizniZprava = (
    values: RevizniZpravaInput,
    napetovaUroven: NapetovaUroven,
    cestneProhlaseni: boolean,
    prilohy?: PrilohaUploadInput[],
    update?: boolean,
) => {
    // kontrola dostupnosti objektu proudy
    const proudyNnVvn =
        napetovaUroven === NapetovaUroven.NN && values.odberneMisto?.dataNn?.proudy
            ? values.odberneMisto.dataNn.proudy
            : napetovaUroven !== NapetovaUroven.NN && values.odberneMisto?.dataVn?.proudy
              ? values.odberneMisto.dataVn.proudy
              : undefined;

    // kontrola dostupnosti objektu napětí
    const napetiVn = napetovaUroven !== NapetovaUroven.NN && values.odberneMisto?.dataVn?.napeti;

    // sestavení objektu přístroje proudu
    let pristrojeProud: any[] = [];

    // sestavení objektu převody proudu
    let prevodyProud: RevizniZpravaElektrinaOmPrevodProud[] = [];

    // sestavení objektu třídy přesnosti proudu
    let tridyPresnostiProud: RevizniZpravaElektrinaOmTridaPresnosti[] = [];

    // sestavení objektu přístroje napětí
    let pristrojeNapeti: any[] = [];

    // sestavení objektu převody napětí
    let prevodyNapeti: RevizniZpravaElektrinaOmVnPrevodNapeti[] = [];

    // sestavení objektu třídy přesnosti napětí
    let tridyPresnostiNapeti: RevizniZpravaElektrinaOmTridaPresnosti[] = [];

    // sestavení objektu distribucni sazba na základě hodnoty z formuláře
    let distribucniSazba: RevizniZpravaElektrinaDistribucniSazba | undefined | string = undefined;

    // sestavení objektu proudy pro NN i VN a VVN (jsou vždy)
    let proudy: RevizniZpravaElektrinaOmProud = {
        pristroje: [],
        prevody: [],
        tridyPresnosti: [],
    };

    // sestavení objektu napětí pro VN a VVN (pouze pokud napětí je VN nebo VVN)
    let napeti: RevizniZpravaElektrinaOmVnNapeti = {
        pristroje: [],
        prevody: [],
        tridyPresnosti: [],
    };

    if (!!proudyNnVvn) {
        // sestavení pole přístrojů
        pristrojeProud = Object.values(proudyNnVvn.pristroje).map((proud) => ({
            cislo: proud,
        }));

        // sestavení pole převodů
        prevodyProud = Object.values(proudyNnVvn.prevody).map((prevod: RevizniZpravaElektrinaOmPrevodProud) => prevod);

        // sestavení pole tříd přístrojů
        tridyPresnostiProud = Object.values(proudyNnVvn.tridyPresnosti).map((trida: RevizniZpravaElektrinaOmTridaPresnosti) => trida);

        // nasetování do objektu
        proudy = {
            pristroje: pristrojeProud,
            prevody: prevodyProud,
            tridyPresnosti: tridyPresnostiProud,
        };
    }

    // výběr distribuční sazby z číselníku dynamicky
    if (napetovaUroven === NapetovaUroven.NN) {
        distribucniSazba =
            values.odberneMisto?.dataNn?.distribucniSazba &&
            RevizniZpravaElektrinaDistribucniSazba[
                `${values.odberneMisto.dataNn.distribucniSazba.toUpperCase()}` as keyof typeof RevizniZpravaElektrinaDistribucniSazba
            ];
    }

    if (napetovaUroven !== NapetovaUroven.NN && !!napetiVn) {
        // sestavení pole přístrojů
        pristrojeNapeti = Object.values(values.odberneMisto.dataVn?.napeti.pristroje ?? {})?.map((pristroj) => ({
            cislo: pristroj,
        }));

        // sestavení pole převodů
        prevodyNapeti = Object.values(values.odberneMisto.dataVn?.napeti.prevody ?? {}).map((prevod: RevizniZpravaElektrinaOmVnPrevodNapeti) => prevod);

        // sestavení pole tříd přístrojů
        tridyPresnostiNapeti = Object.values(values.odberneMisto.dataVn?.napeti.tridyPresnosti ?? {}).map(
            (trida: RevizniZpravaElektrinaOmTridaPresnosti) => trida,
        );

        // nasetování do objektu
        napeti = {
            pristroje: pristrojeNapeti,
            prevody: prevodyNapeti,
            tridyPresnosti: tridyPresnostiNapeti,
        };
    }

    // sestavení objektu spotřebiče
    const spotrebice =
        napetovaUroven === NapetovaUroven.NN && values.odberneMisto?.dataNn?.spotrebice
            ? values.odberneMisto.dataNn.spotrebice
                  .map((spotrebic) => ({
                      typ: spotrebic?.typ,
                      prikon: parseDecimal(spotrebic?.prikon),
                  }))
                  .filter((spotrebic) => !!spotrebic?.prikon)
            : null;

    // vytvoření objektu pro podání revizní zprávy
    const createRevizniZprava = {
        typPotvrzeni: !!values.typPotvrzeni ? values.typPotvrzeni : '',
        vystaveno: !!values.vystaveno ? values.vystaveno : null,
        prilohy: !!prilohy ? prilohy : values.prilohy,
        odberneMisto: {
            ean: values.odberneMisto?.ean ?? '',
            napetovaUroven: values.odberneMisto?.napetovaUroven ?? null,
            schopnostBezpecnehoProvozu: values.odberneMisto?.schopnostBezpecnehoProvozu ?? null,
            dataNn:
                napetovaUroven === NapetovaUroven.NN
                    ? {
                          hodnotaJistice: values.odberneMisto?.dataNn?.hodnotaJistice?.toString() ?? null,
                          pocetFazi: values.odberneMisto?.dataNn?.pocetFazi ?? null,
                          vypinaciCharakteristika: values.odberneMisto?.dataNn?.vypinaciCharakteristika ?? null,
                          distribucniSazba: distribucniSazba ?? '',
                          cestneProhlaseni,
                          proudy: proudy.pristroje.length > 0 ? proudy : null,
                          spotrebice,
                      }
                    : null,
            dataVn:
                napetovaUroven !== NapetovaUroven.NN
                    ? {
                          poznamka: !!values.odberneMisto.dataVn?.poznamka ? values.odberneMisto.dataVn.poznamka : null,
                          proudy: proudy.pristroje.length > 0 ? proudy : null,
                          napeti,
                      }
                    : null,
        },
    };

    // vytvoření objektu pro update revizní zprávy
    const updateRevizniZprava = {
        typPotvrzeni: !!values.typPotvrzeni ? values.typPotvrzeni : '',
        vystaveno: !!values.vystaveno ? values.vystaveno : null,
        prilohy: !!prilohy ? prilohy : values.prilohy,
        odberneMisto: {
            schopnostBezpecnehoProvozu: values.odberneMisto?.schopnostBezpecnehoProvozu ?? null,
            dataNn:
                napetovaUroven === NapetovaUroven.NN
                    ? {
                          pocetFazi: values.odberneMisto?.dataNn?.pocetFazi ?? null,
                          vypinaciCharakteristika: values.odberneMisto?.dataNn?.vypinaciCharakteristika ?? null,
                          cestneProhlaseni,
                          proudy: proudy.pristroje.length > 0 ? proudy : null,
                          spotrebice,
                      }
                    : null,
            dataVn:
                napetovaUroven !== NapetovaUroven.NN
                    ? {
                          poznamka: !!values.odberneMisto.dataVn?.poznamka ? values.odberneMisto.dataVn.poznamka : null,
                          proudy: proudy.pristroje.length > 0 ? proudy : null,
                          napeti,
                      }
                    : null,
        },
    };

    // sestavení celého inputu revizní zprávy pro odeslání na backend nebo k validaci
    return update ? updateRevizniZprava : createRevizniZprava;
};

export type RevizniZpravaInput =
    | {
          idRevizniZprava: string;
          vystaveno: any;
          prilohy: Priloha[];
          typPotvrzeni: RevizniZpravaElektrinaPotvrzeniTyp;
          odberneMisto: {
              dataNn: {
                  distribucniSazba: string;
                  spotrebice: RevizniZpravaElektrinaOmNnSpotrebic[];
                  proudy: RevizniZpravaElektrinaOmProudInput;
                  cestneProhlaseni: boolean;
                  hodnotaJistice: number;
                  pocetFazi: RevizniZpravaElektrinaOmNnPocetFazi;
                  vypinaciCharakteristika: RevizniZpravaElektrinaOmNnVypinaciCharakteristika;
              };
              dataVn?: RevizniZpravaElektrinaOmVn;
              ean: string;
              napetovaUroven: NapetovaUroven;
              schopnostBezpecnehoProvozu: RevizniZpravaElektrinaOmBezpecnyProvoz;
          };
      }
    | {
          idRevizniZprava: string;
          vystaveno: any;
          prilohy: Priloha[];
          typPotvrzeni: RevizniZpravaElektrinaPotvrzeniTyp;
          odberneMisto: {
              dataVn?: {
                  proudy: RevizniZpravaElektrinaOmProudInput;
                  napeti: RevizniZpravaElektrinaOmVnNapeti;
                  poznamka?: string;
              };
              dataNn?: RevizniZpravaElektrinaOmNn & {distribucniSazba: string};
              ean: string;
              napetovaUroven: NapetovaUroven;
              schopnostBezpecnehoProvozu: RevizniZpravaElektrinaOmBezpecnyProvoz;
          };
      };
