import {gql} from '@apollo/client';

export const UcetMutations = {
    gql: {
        setActivePristup: gql`
            mutation SetActivePristup($idPristupu: ID!, $komodita: Komodita!) {
                ucty {
                    pristupy {
                        setActive(idPristupu: $idPristupu, komodita: $komodita) {
                            id
                            skupina {
                                id
                                nazev
                                elektrina
                                plyn
                            }
                        }
                    }
                }
            }
        `,
        enableBiometrics: gql`
            mutation EnableBiometrics($input: EnableBiometricsInput!) {
                ucty {
                    enableBiometrics(input: $input)
                }
            }
        `,

        sendMobileToken: gql`
            mutation SendMobileToken($input: AddFcmRegistrationTokenInput!) {
                ucty {
                    addFcmRegistrationToken(input: $input)
                }
            }
        `,
        revokeMobileToken: gql`
            mutation RevokeMobileToken($input: RevokeFcmRegistrationTokenInput!) {
                ucty {
                    revokeFcmRegistrationToken(input: $input)
                }
            }
        `,

        unsetActivePristup: gql`
            mutation UnsetActivePristup {
                ucty {
                    pristupy {
                        unsetActive
                    }
                }
            }
        `,
        prihlasitDoSPP: gql`
            mutation PrihlasitDoSPP {
                ucty {
                    prihlasitDoSPP {
                        code
                        redirectUri
                    }
                }
            }
        `,
        prihlasitDoVyjadrovacky: gql`
            mutation PrihlasitDoVyjadrovacky {
                ucty {
                    prihlasitVyjLinkaWeb {
                        code
                        redirectUri
                    }
                }
            }
        `,
        sendRateMobileApp: gql`
            mutation SendRateMobileApp($action: MobileAppRatingAction!, $rating: MobileAppRating) {
                ucty {
                    rateMobileApp(action: $action, rating: $rating)
                }
            }
        `,
    },
};
