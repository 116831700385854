import {Komodita} from '@eon.cz/apollo13-graphql';
import Cookie from 'js-cookie';
import {NextApiRequest} from 'next';
import Router from 'next/router';
import {changeFavicon} from '../../Common/services/CommonService';

export const ProfileService = {
    // Nastaví komoditu do cookie
    setKomodita(komodita: Komodita | null | undefined, withoutRoute: boolean): void {
        Cookie.set('komodita', komodita ?? Komodita.ELEKTRINA, {expires: 360});
        if (!withoutRoute) {
            Router.push(`/${komodita?.toLowerCase()}`);
        }
        changeFavicon(komodita);
    },
    // Smaze komoditu z cookie
    removeKomodita(): void {
        Cookie.remove('komodita');
    },

    // přečte komoditu z cookie
    getKomodita(): Komodita | null {
        const komodita: any = Cookie.get('komodita');
        if (!komodita && (komodita !== Komodita.PLYN || komodita !== Komodita.ELEKTRINA)) {
            return null;
        }
        return komodita;
    },

    // zjistí komoditu ze síťového dotazu
    getKomoditaFromReq(req: NextApiRequest): Komodita | null {
        const komodita: any = req && req.cookies ? req.cookies.komodita : null;
        if (!komodita && (komodita !== Komodita.PLYN || komodita !== Komodita.ELEKTRINA)) {
            return null;
        }
        return komodita;
    },

    setMockSap(mockSap: boolean): void {
        if (typeof window !== 'undefined') {
            if (mockSap) {
                localStorage.setItem('mock-sap', 'true');
            } else {
                localStorage.removeItem('mock-sap');
            }
        }
    },

    isMockSap(): boolean {
        return process.browser ? !!localStorage.getItem('mock-sap') : false;
    },
};
