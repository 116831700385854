import {Button} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';

type Props = {
    isMobile: boolean;
};
export const CookiesButton = ({isMobile}: Props) => {
    const toggleCookiesDialog = () => {
        window?.UC_UI?.showFirstLayer();
    };

    return (
        <>
            {!isMobile && (
                <Button
                    sx={{
                        fontSize: 15,
                        borderRadius: '10px 10px 0px 0px',
                        backgroundColor: 'background.default',
                        color: 'secondary.main',
                        '&:hover': {
                            backgroundColor: 'background.default',
                            color: 'secondary.main',
                        },
                    }}
                    onClick={toggleCookiesDialog}
                >
                    <FormattedMessage id={Lang.LOGIN_COOKIES_SETTING} />
                </Button>
            )}
            {isMobile && (
                <Button
                    onClick={toggleCookiesDialog}
                    sx={{
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <FormattedMessage id="login.cookies.setting" />
                </Button>
            )}
        </>
    );
};
